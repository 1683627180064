@import 'src/app/shared/styles/variables.scss';

:host {
    cursor: pointer;
    display: inline-flex;
    font-size: $fmh-dash-font-size-medium;
    color: $fmh-dash-font-color-base;
    margin-bottom: 5px;

    &::before {
      content: "";
      padding-block-start: 0.3em;
      width: 0.5em;
      margin-inline-start: 0.2em;
      margin-inline-end: 1em;
      background: url('../../../../assets/icon/Back-Arrow.svg') center / contain no-repeat;
    }
}

