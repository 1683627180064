/* Gray scale */
$fmh-dash-color-gray-100: #fff;
$fmh-dash-color-gray-200: #f2f2f2;
$fmh-dash-color-gray-300: #e3e3e3;
$fmh-dash-color-gray-400: #ccc;
$fmh-dash-color-gray-450: #aaa;
$fmh-dash-color-gray-a0a: #a0a0a0;
$fmh-dash-color-gray-500: #999;
$fmh-dash-color-gray-656: #656565;
$fmh-dash-color-gray-600: #666;
$fmh-dash-color-gray-700: #464646;
$fmh-dash-color-gray-757: #757575;
$fmh-dash-color-gray-800: #333;
$fmh-dash-color-gray-900: #000;

/* Blue Scale */
$fmh-dash-color-blue-100: #eaf9fa;
$fmh-dash-color-blue-200: #bcd4d4;
$fmh-dash-color-blue-300: #d6ecee;
$fmh-dash-color-blue-500: #c0e0e2;
$fmh-dash-color-blue-700: #005f6b;
$fmh-dash-color-blue-900: #045360;

/* Green Scale */
$fmh-dash-color-green-300: #8cd600;
$fmh-dash-color-green-500: #7ab800;
$fmh-dash-color-green-700: #5b8f22;

/* Other Colors */
$fmh-dash-color-red: #CE0037;
$fmh-dash-color-red-orange: #e06020;
$fmh-dash-color-orange: #f79225;
$fmh-dash-color-orange-light: #ffe3c4;
$fmh-dash-color-yellow: #ffcb33;
$fmh-dash-color-purple: #78496a;
$fmh-dash-color-brown: #b2a284;
$fmh-dash-color-pink: #ee88a3;

/* Font Sizes */
$fmh-dash-font-size-2xlarge: 1.5rem;
$fmh-dash-font-size-xlarge: 1.25rem;
$fmh-dash-font-size-large: 1.125rem;
$fmh-dash-font-size-medium: 1rem;
$fmh-dash-font-size-base: 0.875rem;
$fmh-dash-font-size-small: 0.75rem;
$fmh-dash-font-size-xsmall: 0.6875rem;
$fmh-dash-font-size-2xsmall: 0.5rem;

/* Font Weights */
$fmh-dash-font-weight-bold: 700;
$fmh-dash-font-weight-medium: 600;
$fmh-dash-font-weight-normal: 400;

/* Font Colors */
$fmh-dash-font-color-base: #292929;
$fmh-dash-font-color-blue: $fmh-dash-color-blue-700;
$fmh-dash-font-color-purple: $fmh-dash-color-purple;
$fmh-dash-font-form-label-color: #1A1A1A;
$fmh-dash-font-subtle-color: #757575;
$fmh-dash-font-slightly-subtle-color: #383838;
$fmh-dash-font-placeholder-color: #717D8A;
$fmh-dash-font-emphasis-color: #000000;

/* Logical Colors */
$fmh-dash-color-primary: #007988;
$fmh-dash-color-primary-light: #2492A8;
$fmh-dash-color-primary-dark: #005F6B;
$fmh-dash-color-primary-highlight: #DEEFF2;

$fmh-dash-color-secondary: #929292;
$fmh-dash-color-secondary-border: #CCCECF;
$fmh-dash-color-secondary-light: #979797;
$fmh-dash-color-secondary-dark: #8A8A8A;

$fmh-dash-color-alert: #CD0035;
$fmh-dash-color-critical: #CE0037;

$general-icon-check: "\f00c";
$general-icon-circle: "\f111";
$general-icon-search: "\f002";

/* Spacing */
$fmh-dash-spacing-small: 0.25rem;
$fmh-dash-spacing-medium: 0.5rem;
$fmh-dash-spacing-large: 1rem;
$fmh-dash-spacing-xlarge: 2rem;

$fmh-dash-form-field-border-radius: 8px;
$fmh-dash-button-border-radius: 6px;
